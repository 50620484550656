import React from "react";
import { Box } from "@mui/material";

const Thermometer = ({ value, max, min, label, orient = "vertical" }) => {
  // Calcular el valor normalizado (0-100)
  const normalizedValue = ((value - min) / (max - min)) * 100;

  // Determinar si no hay valor (0 o "none")
  const noValue = value === 0 || value === "none";

  const getThermometerColor = (score) => {
    if (noValue) {
      return "black";
    }
    if (score > 60) {
      return "temperature.high";
    } else if (score > 30) {
      return "temperature.medium";
    } else {
      return "temperature.low";
    }
  };

  const getThermometerBackgroundColor = (score) => {
    if (noValue) {
      return "black";
    }
    if (score > 60) {
      return "temperature.highDark";
    } else if (score > 30) {
      return "temperature.mediumDark";
    } else {
      return "temperature.lowDark";
    }
  };

  const isHorizontal = orient === "horizontal";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isHorizontal ? "row" : "column",
        alignItems: "center",
        justifyContent: isHorizontal ? "flex-start" : "flex-end",
        width: isHorizontal ? "6.2rem" : "1rem",
        height: isHorizontal ? "1.5rem" : "6.2rem",
        padding: "0.5rem",
        backgroundColor: getThermometerColor(normalizedValue),
        borderRadius: isHorizontal ? "0 2px 2px 0" : "2px 0 0 2px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: isHorizontal ? "100%" : "60%",
          height: isHorizontal ? "40%" : "100%",
          position: "relative",
          overflow: "hidden",
          borderRadius: "20px",
          backgroundColor: getThermometerBackgroundColor(normalizedValue),
        }}
      >
        {!noValue && (
          <Box
            sx={{
              position: "absolute",
              bottom: isHorizontal ? "auto" : 0,
              left: isHorizontal ? 0 : "auto",
              height: isHorizontal ? "100%" : `${normalizedValue}%`,
              width: isHorizontal ? `${normalizedValue}%` : "100%",
              backgroundColor: "background.paper",
              transition: "width 0.5s ease, height 0.5s ease",
              borderRadius: isHorizontal ? "20px 0 0 20px" : "0 0 20px 20px",
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Thermometer;
