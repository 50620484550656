import React from "react";

const Footer = () => {
  return (
    <footer style={{
      position: "fixed",
      zIndex: 1500,
      bottom: 0,
      left: 0,
      right: 0,
      width: "100%",
      backgroundColor: "#373C47",
      color: "white",
      padding: "10px 15px",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: "15px",
      fontSize: "14px",
      boxSizing: "border-box",
    }}>
      <a
        href="https://www.instagram.com/app.smartdx"
        target="_blank"
        rel="noopener noreferrer"
        className="footer-link"
      >
        <img
          src="/instagram.png"
          alt="Instagram"
          className="footer-icon"
        />
        <span className="footer-text">@app.smartdx</span>
      </a>
      <a
        href="https://www.tiktok.com/@app.smartdx"
        target="_blank"
        rel="noopener noreferrer"
        className="footer-link"
      >
        <img
          src="/tiktok.png"
          alt="TikTok"
          className="footer-icon"
        />
        <span className="footer-text">@app.smartdx</span>
      </a>
      <a
        href="mailto:app.smartdx@gmail.com"
        className="footer-link"
      >
        <img
          src="/mail.png"
          alt="Mail"
          className="footer-icon"
        />
        <span className="footer-text">app.smartdx@gmail.com</span>
      </a>
      <style>
        {`
          .footer-link {
            color: white;
            text-decoration: none;
            display: flex;
            align-items: center;
            gap: 5px;
          }

          .footer-icon {
            width: 20px;
            height: 20px;
          }

          .footer-text {
            display: inline; /* Texto visible por defecto */
          }

          @media (max-width: 600px) {
            .footer-text {
              display: none; /* Oculta el texto en pantallas pequeñas */
            }

            footer {
              justify-content: flex-end; /* Mantén los íconos a la derecha */
              gap: 10px;
            }

            .footer-icon {
              width: 24px; /* Aumenta ligeramente el tamaño de los íconos en móvil */
              height: 24px;
            }
          }
        `}
      </style>
    </footer>
  );
};

export default Footer;
