import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RawQuery from "./views/RawQuery";
import Authentication from "./views/Authentication";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import WaitingForAccess from "./views/WaitingForAccess";
import Record from "./views/Record";
import DxFinder from "./views/DxFinder";
import DxGraph from "./views/DxGraph";
import DxNavbar from "./components/navbars/DxNavbar";
import Findings from "./views/admin/Findings";
import Keywords from "./views/admin/Keywords";
import TermsAndConditions from "./views/authentication/TermsAndConditions"; // Nueva importación
import Footer from "./components/Footer"; // Importa el Footer

function App() {
  return (
    <Router>
      <DxNavbar />
      {/* Contenedor principal con padding-bottom para evitar superposición */}
      <div
        style={{
          minHeight: "100vh", // Asegura que el contenido ocupe toda la altura
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flex: 1, // Hace que este contenedor crezca y ocupe todo el espacio disponible
            paddingBottom: "60px", // Altura del Footer para evitar solapamiento
          }}
        >
          <Routes>
            <Route path="/" element={<Authentication />} />
            <Route path="/authentication" element={<Authentication />} />
            <Route path="/waiting-for-access" element={<WaitingForAccess />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route element={<ProtectedRoute />}>
              <Route path="/raw-query" element={<RawQuery />} />
              <Route path="/finder" element={<DxFinder />} />
              <Route path="/record/:recordId" element={<Record />} />
              <Route path="/graph/:recordId" element={<DxGraph />} />
              <Route path="/admin/findings" element={<Findings />} />
              <Route path="/admin/keywords" element={<Keywords />} />
            </Route>
            <Route path="*" element={<p>There's nothing here: 404!</p>} />
          </Routes>
        </div>
        <Footer /> {/* Footer fijo al fondo */}
      </div>
    </Router>
  );
}

export default App;
