import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, Stack, Tooltip, Typography, Button } from "@mui/material";
import { Thermometer } from "../progress-bars"; // Asegúrate de que el Thermometer ya esté modificado
import { Assignment, Insights, MoreVert } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { getPathInfo } from "../../helpers/paths-info-helpers";
import { useDxData } from "../context/DxDataContext";
import { useIsMobile } from "../../hooks";

const SpecificDxResultCard = ({ ranking, specificDx }) => {
  const navigate = useNavigate();
  const { dxData } = useDxData();
  const isMobile = useIsMobile();

  const isResult = typeof specificDx.score === "number";

  const goToRecord = () => {
    navigate(`/record/${specificDx.name}`);
  };

  const goToGraph = (specificDx) => {
    const { dxPathsInfo } = getPathInfo(specificDx.name, dxData?.paths_info);
    const encodedPathsInfo = encodeURIComponent(JSON.stringify(dxPathsInfo));
    const encodedInputFindings = encodeURIComponent(
      JSON.stringify(dxData?.input_findings)
    );

    navigate(
      `/graph/${specificDx.name}?paths_info=${encodedPathsInfo}&input_findings=${encodedInputFindings}`
    );
  };

  // Si el score no está definido o no es un número, usar "none"
  const valueForThermometer = 
    (typeof specificDx.score === "number") ? specificDx.score : "none";

  return (
    <Grid item>
      <Stack
        direction="row"
        sx={{
          backgroundColor: "background.paper",
          borderRadius: "2px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
          height: isMobile ? "7.2rem" : "auto",
          overflow: "hidden",
        }}
      >
        <Thermometer
          value={valueForThermometer}
          max={1}
          min={0}
          orient={isMobile ? "vertical" : "horizontal"}
        />
        <Stack
          onClick={goToRecord}
          direction="row"
          spacing={1}
          sx={{
            cursor: "pointer",
            padding: "0.5rem",
            width: "100%",
            paddingY: 1,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              backgroundColor: "background.lighter",
              padding: "0.2rem",
              borderRadius: "10rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "1rem",
              height: "1rem",
              flexShrink: 0,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "text.dark",
                fontWeight: 700,
              }}
            >
              {ranking}
            </Typography>
          </Box>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 700,
              color: "text.dark",
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: isMobile ? "normal" : "nowrap",
              display: isMobile ? "-webkit-box" : "block",
              WebkitBoxOrient: isMobile ? "vertical" : "horizontal",
              WebkitLineClamp: isMobile ? 2 : "unset",
              lineHeight: "1.2",
            }}
          >
            {specificDx.name}
          </Typography>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="center"
          sx={{
            boxShadow: "inset 3px 0px 3px rgba(0, 0, 0, 0.2)",
            flexShrink: 0,
          }}
        >
          <Tooltip
            title={
              isResult
                ? "Ver gráfico"
                : "Este diagnóstico viene de la consulta"
            }
            arrow
            placement="top"
          >
            <Button
              onClick={() => goToGraph(specificDx)}
              disabled={!isResult}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingY: "0.45rem",
                paddingX: "0.5rem",
                cursor: "pointer",
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "primary.main",
                  color: "background.paper",
                },
                "&.Mui-disabled": {
                  cursor: "not-allowed",
                  pointerEvents: "auto",
                  color: "text.disabled",
                },
              }}
            >
              <Insights />
            </Button>
          </Tooltip>
          <Tooltip title="Ver ficha" arrow placement="top">
            <Box
              onClick={goToRecord}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingY: "0.45rem",
                paddingX: "0.5rem",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "primary.main",
                  color: "background.paper",
                },
              }}
            >
              <Assignment />
            </Box>
          </Tooltip>
          <Tooltip
            title="Próximamente más funcionalidades"
            arrow
            placement="top"
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingY: "0.45rem",
                paddingX: "0.5rem",
                color: "background.lighter",
              }}
            >
              <MoreVert />
            </Box>
          </Tooltip>
        </Stack>
      </Stack>
    </Grid>
  );
};

SpecificDxResultCard.propTypes = {
  ranking: PropTypes.number.isRequired,
  specificDx: PropTypes.object.isRequired,
};

export default SpecificDxResultCard;
