import React, { useState, useCallback, useRef, useMemo } from "react";
import {
  Box,
  Autocomplete,
  TextField,
  ListItem,
  IconButton,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import getClient from "../../helpers/get-client";

const KeyWordSearchBar = ({ selectedKeywords, setSelectedKeywords }) => {
  const client = useMemo(() => getClient(), []);
  const [keywords, setKeywords] = useState([]);
  const [searchWord, setSearchWord] = useState(""); // Texto mostrado en la barra
  const [highlightedOption, setHighlightedOption] = useState(null); // Opción resaltada (única)
  const [loading, setLoading] = useState(false);
  const debounceTimeout = useRef(null);

  // Maneja el cambio de texto en la barra de búsqueda
  const handleSearchWordChange = (event, newInputValue) => {
    setSearchWord(newInputValue); // Actualiza el texto principal

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      if (newInputValue) {
        setLoading(true);
        fetchKeywords(newInputValue);
      } else {
        setKeywords([]);
      }
    }, 900); // Debounce de 900ms
  };

  // Fetch de palabras clave relacionadas
  const fetchKeywords = useCallback(
    (searchWord) => {
      if (!client || !searchWord) return;

      client
        .get(
          `${process.env.REACT_APP_BACKEND_URI}api/medical_analysis/get-related-keywords/`,
          {
            params: { searchWord },
          }
        )
        .then((response) => {
          setKeywords(response.data.related_keywords);
        })
        .catch((error) => {
          console.error("Error fetching keywords:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [client]
  );

  // Maneja la selección de una palabra clave
  const handleKeywordSelect = useCallback(
    (keyword) => {
      if (keyword && !selectedKeywords[keyword]) {
        setSelectedKeywords({ ...selectedKeywords, [keyword]: 1 });
      }
      setSearchWord(keyword); // Actualiza el texto principal
      setHighlightedOption(null); // Limpia el resaltado
      setKeywords([]);
    },
    [selectedKeywords, setSelectedKeywords]
  );

  // Maneja el Enter para seleccionar la opción resaltada
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && highlightedOption) {
      event.preventDefault();
      handleKeywordSelect(highlightedOption); // Selecciona la opción resaltada
    }
  };

  return (
    <Box width={"80%"}>
      <Autocomplete
        freeSolo
        options={keywords}
        inputValue={searchWord} // Texto mostrado en la barra
        onInputChange={handleSearchWordChange}
        onHighlightChange={(event, option) => {
          setHighlightedOption(option); // Resalta al navegar con el teclado
        }}
        filterOptions={(options) => options} // No filtrar las opciones
        getOptionLabel={(option) => option}
        onKeyDown={handleKeyDown}
        onChange={(event, newValue) => {
          if (newValue) {
            setSearchWord(newValue); // Actualiza el texto principal al confirmar selección
            setHighlightedOption(null); // Limpia el resaltado
          }
        }}
        disablePortal // Evita que el espacio en blanco se reserve para la lista desplegable
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            placeholder="Buscar"
            sx={{
              color: "white",
              backgroundColor: "#1d1d1d",
              borderRadius: "500px",
              "&:focus": { backgroundColor: "primary.main" },
              "& .MuiOutlinedInput-root fieldset": { border: "none" },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
              startAdornment: (
                <SearchIcon sx={{ color: "white", marginLeft: "0.5rem" }} />
              ),
            }}
          />
        )}
        ListboxProps={{
          sx: {
            position: "absolute", // Se renderiza como flotante
            zIndex: 1500,
            backgroundColor: "#1d1d1d",
            border: "none",
            padding: "3px",
            marginTop: "5px", // Separación en el eje Y
            width: "90%", // Ajusta el ancho dinámicamente (90% del contenedor padre)
            maxWidth: "400px", // Limita el ancho máximo
            borderRadius: "8px", // Bordes redondeados
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)", // Sombra para destacar la lista
            left: "50%", // Centra horizontalmente
            transform: "translateX(-50%)", // Ajusta el punto de anclaje al centro
            "& .MuiAutocomplete-option": {
              color: "#E0E0E0",
              fontSize: "0.8rem",
              "&:hover": {
                backgroundColor: "#373C47",
              },
              "&.Mui-focused": {
                backgroundColor: "#373C47",
              },
            },
          },
        }}
        
        renderOption={(props, option) => (
          <ListItem
            {...props}
            onMouseEnter={() => setHighlightedOption(option)} // Resalta al pasar el mouse
            sx={{
              backgroundColor:
                highlightedOption === option ? "#373C47" : "transparent", // Resaltar fondo
              color: "white",
              "& .MuiIconButton-root": {
                visibility: highlightedOption === option ? "visible" : "hidden",
              },
            }}
            onClick={() => handleKeywordSelect(option)}
            secondaryAction={
              <IconButton
                edge="end"
                size="small"
                sx={{
                  padding: "3px", // Más compacto
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  handleKeywordSelect(option);
                }}
              >
                <AddIcon
                  sx={{
                    color: "white",
                    fontSize: "16px", // Tamaño más pequeño
                    visibility: highlightedOption === option ? "visible" : "hidden",
                  }}
                />
              </IconButton>
            }
          >
            {option}
          </ListItem>
        )}
      />
    </Box>
  );
};

KeyWordSearchBar.propTypes = {
  selectedKeywords: PropTypes.object.isRequired,
  setSelectedKeywords: PropTypes.func.isRequired,
};

export default KeyWordSearchBar;
