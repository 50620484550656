import React from "react";
import PropTypes from "prop-types";
import { Box, Modal, Stack, Typography } from "@mui/material";
import SpecificDxResultCard from "../cards/SpecificDxResultCard";

const SpecificDxsModal = ({
  openSpecificDxs,
  specificDxs,
  selectedGroup,
  handleGroupClose,
}) => {
  return (
    <Modal
      open={openSpecificDxs && specificDxs.length > 0}
      onClose={handleGroupClose}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          zIndex: 2000, // Agrega un z-index alto
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.dark",
          borderRadius: "8px", // Bordes redondeados del modal
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)", // Sombra inicial
          transition: "all 0.3s ease-in-out", // Transición suave para la sombra
          "&:hover": {
            boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.6)", // Sombra más prominente al pasar el mouse
          },
          p: 2, // Espaciado interno
          maxHeight: "90vh",
          overflowY: "auto", // Scroll interno para contenido largo
        }}
      >
        <Stack
          direction="column"
          alignItems="center"
          sx={{
            paddingX: { xs: 0, sm: "5rem" },
            paddingBottom: { xs: 0, sm: "0.5rem" },
          }}
        >
          <Stack direction="column" textAlign="center" aria-label="header">
            <Typography
              variant="body2"
              sx={{
                mb: 1,
                fontStyle: "italic",
                color: "text.secondary",
              }}
            >
              Diagnósticos / Exámenes del grupo
            </Typography>
            <Typography
              variant="h5"
              fontWeight={700}
              sx={{
                mb: 2,
                color: "text.primary",
              }}
            >
              {selectedGroup}
            </Typography>
          </Stack>
          <Stack
            direction="column"
            spacing={1.5}
            sx={{
              width: "100%",
            }}
          >
            {specificDxs.map((specificDx, index) => (
              <Box
                key={specificDx.name}
                sx={{
                  transition: "all 0.3s ease-in-out", // Transición suave
                  "&:hover": {
                    transform: "scale(1.03)", // Aumenta ligeramente el tamaño
                    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.3)", // Sombra más prominente
                  },
                }}
              >
                <SpecificDxResultCard
                  ranking={index + 1}
                  specificDx={specificDx}
                />
              </Box>
            ))}
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

SpecificDxsModal.propTypes = {
  openSpecificDxs: PropTypes.bool.isRequired,
  specificDxs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      score: PropTypes.number.isRequired,
    })
  ).isRequired,
  selectedGroup: PropTypes.string.isRequired,
  handleGroupClose: PropTypes.func.isRequired,
};

export default SpecificDxsModal;
