import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
  Checkbox,
  TextField,
  Stack,
  Typography,
} from "@mui/material";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";

const SettingsForm = ({ values, setValue }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box
        onClick={handleOpen}
        sx={{
          color: "text.secondary",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        }}
      >
        <SettingsApplicationsIcon
          sx={{
            fontSize: "2rem",
            color: "text.secondary",
            "&:hover": {
              color: "primary.main",
            },
          }}
        />
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle
          sx={{
            backgroundColor: "primary.main",
            color: "white",
            fontWeight: "bold",
            textAlign: "center",
            padding: "12px",
          }}
        >
          Ajustes de Consulta
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "background.default",
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <Stack spacing={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography sx={{ color: "text.secondary", fontSize: "1rem" }}>
                ¿Incluir antecedentes?
              </Typography>
              <Checkbox
                checked={values?.include_med_history}
                onChange={(event) =>
                  setValue(event.target.checked, "include_med_history", "boolean")
                }
                sx={{
                  color: "text.secondary",
                  "&.Mui-checked": {
                    color: "primary.main",
                  },
                }}
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography sx={{ color: "text.secondary", fontSize: "1rem" }}>
                ¿Incluir fármacos?
              </Typography>
              <Checkbox
                checked={values?.include_drugs}
                onChange={(event) =>
                  setValue(event.target.checked, "include_drugs", "boolean")
                }
                sx={{
                  color: "text.secondary",
                  "&.Mui-checked": {
                    color: "primary.main",
                  },
                }}
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography sx={{ color: "text.secondary", fontSize: "1rem" }}>
                Nº Mínimo de Hallazgos:
              </Typography>
              <TextField
                variant="outlined"
                type="number"
                value={values?.min_findings_out}
                onChange={(event) =>
                  setValue(event.target.value, "min_findings_out", "number")
                }
                sx={{
                  width: "60px",
                  "& input": {
                    padding: "4px",
                    textAlign: "center",
                    backgroundColor: "black", // Fondo negro
                    color: "white", // Texto blanco para contraste
                  },
                }}
                inputProps={{ min: 1 }}
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography sx={{ color: "text.secondary", fontSize: "1rem" }}>
                Nº Máximo de Conexiones:
              </Typography>
              <TextField
                variant="outlined"
                type="number"
                value={values?.max_connections_out}
                onChange={(event) =>
                  setValue(event.target.value, "max_connections_out", "number")
                }
                sx={{
                  width: "60px",
                  "& input": {
                    padding: "4px",
                    textAlign: "center",
                    backgroundColor: "black", // Fondo negro
                    color: "white", // Texto blanco para contraste
                  },
                }}
                inputProps={{ min: 1, max: 2 }}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: "primary.main",
            padding: "8px",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              color: "white",
              borderColor: "white",
              fontSize: "0.875rem",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              },
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            sx={{
              backgroundColor: "secondary.main",
              color: "white",
              fontSize: "0.875rem",
              "&:hover": {
                backgroundColor: "secondary.dark",
              },
            }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

SettingsForm.propTypes = {
  values: PropTypes.shape({
    include_med_history: PropTypes.bool.isRequired,
    include_drugs: PropTypes.bool.isRequired,
    min_findings_out: PropTypes.number.isRequired,
    max_connections_out: PropTypes.number.isRequired,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
};

export default SettingsForm;
