// AuthenticationForm.js
import React, { useState } from "react";
import { Button, Stack, Typography, Box, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useCurrentUser } from "../../components/context/UserContext";
import { register, login, logout } from "../../helpers/authentication-helpers";
import { DxTextField } from "../../components/forms";
import TermsAndConditions from "./TermsAndConditions";

const AuthenticationForm = () => {
  const [registrationToggle, setRegistrationToggle] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    acceptTerms: false,
  });

  const { currentUser } = useCurrentUser();

  const isFormValid = () => {
    if (registrationToggle) {
      return (
        formData.username.trim() &&
        formData.email.trim() &&
        formData.password.trim() &&
        formData.acceptTerms
      );
    }
    return formData.email.trim() && formData.password.trim();
  };

  const submitRegistration = (event) => {
    event.preventDefault();
    if (!formData.acceptTerms) {
      setErrorMessage("Debes aceptar los términos y condiciones.");
      return;
    }
    register(event, formData, setFormData, setErrorMessage)
      .then(() => {
        login(event, formData, setFormData, setErrorMessage);
      })
      .catch((error) => {
        console.error("Registration error:", error);
      });
  };

  const submitLogin = (event) => {
    event.preventDefault();
    login(event, formData, setFormData, setErrorMessage);
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "background.default",
      }}
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "400px" },
          borderRadius: 2,
          boxShadow: 3,
          padding: 3,
          backgroundColor: "background.dark",
        }}
      >
        {!currentUser ? (
          <form
            onSubmit={registrationToggle ? submitRegistration : submitLogin}
            style={{
              width: "100%",
            }}
          >
            <Stack spacing={2}>
              {registrationToggle && (
                <DxTextField
                  type="text"
                  name="username"
                  label="Nombre de usuario"
                  value={formData.username}
                  handleChange={handleChange}
                  required
                />
              )}

              <DxTextField
                type="email"
                name="email"
                label="Correo electrónico"
                value={formData.email}
                handleChange={handleChange}
                required
              />
              <DxTextField
                type="password"
                name="password"
                label="Contraseña"
                value={formData.password}
                handleChange={handleChange}
                required
              />

              {registrationToggle && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <input
                    type="checkbox"
                    name="acceptTerms"
                    checked={formData.acceptTerms}
                    onChange={handleChange}
                    required
                  />
                  <Typography variant="body2">
                    Acepto los{" "}
                    <span
                      onClick={() => setOpenTermsModal(true)}
                      style={{ 
                        color: "inherit", 
                        textDecoration: "underline", 
                        cursor: "pointer" 
                      }}
                    >
                      términos y condiciones
                    </span>
                    , confirmando que SmartDx es una herramienta de apoyo médico
                    y que las decisiones finales son responsabilidad del
                    profesional de la salud.
                  </Typography>
                </Stack>
              )}
            </Stack>
            {errorMessage && (
              <Typography
                variant="body2"
                sx={{
                  color: "error.main",
                  marginTop: 2,
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                {errorMessage}
              </Typography>
            )}
            <Stack
              direction="column"
              spacing={2}
              sx={{
                marginTop: 3,
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  paddingX: 1.5,
                }}
                disabled={!isFormValid()}
              >
                {registrationToggle ? "Registrarme" : "Ingresar"}
              </Button>
              <Button
                variant="text"
                onClick={() => setRegistrationToggle(!registrationToggle)}
                sx={{
                  width: "100%",
                  textAlign: "center",
                  color: "text.primary",
                }}
              >
                {registrationToggle
                  ? "¿Ya tienes cuenta? Ingresa acá"
                  : "¿Primera vez? ¡Crea una cuenta!"}
              </Button>
            </Stack>
          </form>
        ) : (
          <Stack spacing={2} alignItems="center">
            <Typography variant="h6">
              Bienvenido nuevamente {currentUser.username} ❤️
            </Typography>
            <Button
              variant="contained"
              color="primary"
              href="/raw-query"
              sx={{
                marginTop: 1,
              }}
            >
              Probar SmartDx
            </Button>
            <Button
              onClick={logout}
              variant="outlined"
              color="primary"
              sx={{
                marginTop: 1,
              }}
            >
              Cerrar sesión
            </Button>
          </Stack>
        )}
      </Box>

      {/* Modal estilizado acorde al tema oscuro y con margen inferior */}
      <Dialog 
        open={openTermsModal} 
        onClose={() => setOpenTermsModal(false)} 
        fullWidth 
        maxWidth="md"
        PaperProps={{
          sx: {
            backgroundColor: 'background.dark',
            color: 'text.primary',
            // Ajustar altura máxima y dar un poco de espacio para que no toque el pie de página
            maxHeight: '80vh',
            marginBottom: '40px', // Espacio inferior para separarse del footer
            overflowY: 'auto'
          }
        }}
      >
        <DialogTitle 
          sx={{ 
            backgroundColor: 'background.dark', 
            color: 'text.primary',
            fontWeight: 'bold'
          }}
        >
          Términos y Condiciones
          <IconButton
            aria-label="close"
            onClick={() => setOpenTermsModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'text.primary',
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent 
          dividers 
          sx={{ 
            backgroundColor: 'background.dark', 
            color: 'text.primary' 
          }}
        >
          <TermsAndConditions />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AuthenticationForm;
