import React from "react";
import FindingTwoColumnGrid from "../components/grids/FindingTwoColumnGrid";
import { Grid, Box } from "@mui/material";

const TopExams = ({ examsInfo }) => {
  if (!examsInfo || Object.keys(examsInfo).length === 0) {
    return (
      <Box sx={{ textAlign: "center", padding: "2rem" }}>
        No exams available
      </Box>
    );
  }

  const entries = Object.entries(examsInfo);

  return (
    // Contenedor con scroll interno similar a DxCardGrid
    <Box
      sx={{
        maxHeight: "600px", // Ajusta la altura máxima según tus necesidades
        overflowY: "auto",  // Habilita scroll vertical interno
        overflowX: "hidden",
        border: "1px solid rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        padding: 2,
        backgroundColor: "background.default",
        width: "100%",
      }}
    >
      <Grid
        container
        spacing={3}
        sx={{
          padding: "1rem",
          width: "100%",
          margin: 0,
          // El paddingBottom extra puede mantenerse si quieres más separación
          paddingBottom: "5rem",
        }}
      >
        {/* Primera Categoría */}
        <Grid item xs={12}>
          {entries[0] && (
            <FindingTwoColumnGrid
              title={entries[0][0]}
              exams={entries[0][1]}
              maxHeight="200px"
              columns={{
                xs: 1,
                sm: 3,
                md: 4,
                lg: 5,
              }}
            />
          )}
        </Grid>

        {/* Segunda Categoría */}
        <Grid item xs={12} sm={6} md={6} lg={6}>
          {entries[1] && (
            <FindingTwoColumnGrid
              title={entries[1][0]}
              exams={entries[1][1]}
              maxHeight="150px"
              columns={{
                xs: 1,
                sm: 2,
                md: 3,
              }}
            />
          )}
        </Grid>

        {/* Tercera Categoría */}
        <Grid item xs={12} sm={6} md={6} lg={6}>
          {entries[2] && (
            <FindingTwoColumnGrid
              title={entries[2][0]}
              exams={entries[2][1]}
              maxHeight="150px"
              columns={{
                xs: 1,
                sm: 2,
                md: 3,
              }}
            />
          )}
        </Grid>

        {/* Cuarta Categoría */}
        <Grid item xs={12} sm={6} md={6} lg={6}>
          {entries[3] && (
            <FindingTwoColumnGrid
              title={entries[3][0]}
              exams={entries[3][1]}
              maxHeight="150px"
              columns={{
                xs: 1,
                sm: 2,
                md: 3,
              }}
            />
          )}
        </Grid>

        {/* Quinta Categoría */}
        <Grid item xs={12} sm={6} md={6} lg={6}>
          {entries[4] && (
            <FindingTwoColumnGrid
              title={entries[4][0]}
              exams={entries[4][1]}
              maxHeight="150px"
              columns={{
                xs: 1,
                sm: 2,
                md: 3,
              }}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopExams;
