// TermsAndConditions.js
import React, { useEffect } from "react";
import { Container, Typography, Box } from "@mui/material";

const TermsAndConditions = () => {
  useEffect(() => {
    document.title = "Términos y Condiciones - SmartDx";
  }, []);

  return (
    <Container maxWidth="md">
      <Box sx={{ padding: 5 }}>
        <Typography variant="h4" gutterBottom>
          Términos y Condiciones de Uso de SmartDx
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>1. Introducción</strong>
        </Typography>
        <Typography variant="body2" paragraph>
          Bienvenido a SmartDx, una aplicación diseñada para proporcionar
          recomendaciones y sugerencias en el ámbito médico. Al utilizar
          SmartDx, usted acepta estos términos y condiciones en su totalidad. Si
          no está de acuerdo con alguno de estos términos, por favor no utilice
          la aplicación.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>2. Propósito de la Aplicación</strong>
        </Typography>
        <Typography variant="body2" paragraph>
          SmartDx es una herramienta de apoyo que ofrece recomendaciones y
          sugerencias basadas en información ingresada por el usuario. SmartDx
          no reemplaza el juicio clínico, la experiencia profesional ni las
          decisiones médicas. La responsabilidad última de evaluar y decidir el
          tratamiento médico recae exclusivamente en los profesionales de la
          salud.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>3. Uso del Servicio</strong>
        </Typography>
        <Typography variant="body2" paragraph>
          - El usuario debe ser un profesional de la salud o estar bajo la
          supervisión de uno.
        </Typography>
        <Typography variant="body2" paragraph>
          - El usuario es responsable de la precisión y calidad de los datos
          ingresados en la aplicación.
        </Typography>
        <Typography variant="body2" paragraph>
          - El usuario acepta que SmartDx se utiliza como un complemento y no
          como una fuente única de información.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>4. Limitación de Responsabilidad</strong>
        </Typography>
        <Typography variant="body2" paragraph>
          - SmartDx no se hace responsable de los resultados clínicos derivados
          del uso de la aplicación.
        </Typography>
        <Typography variant="body2" paragraph>
          - SmartDx no garantiza la exactitud, actualidad o integridad de las
          recomendaciones proporcionadas.
        </Typography>
        <Typography variant="body2" paragraph>
          - El usuario acepta que cualquier decisión clínica tomada es su
          responsabilidad exclusiva.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>5. Privacidad de Datos</strong>
        </Typography>
        <Typography variant="body2" paragraph>
          SmartDx se compromete a proteger la privacidad de sus datos de acuerdo
          con las leyes locales de protección de datos. Consulte nuestra{" "}
          <a href="/privacy-policy" style={{ textDecoration: "underline" }}>
            Política de Privacidad
          </a>{" "}
          para obtener más información.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>6. Modificaciones</strong>
        </Typography>
        <Typography variant="body2" paragraph>
          SmartDx se reserva el derecho de modificar estos términos y
          condiciones en cualquier momento. Los cambios se notificarán mediante
          la aplicación, y el uso continuado del servicio implicará la
          aceptación de los nuevos términos.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>7. Propiedad Intelectual</strong>
        </Typography>
        <Typography variant="body2" paragraph>
          Todo el contenido y la tecnología utilizada en SmartDx son propiedad
          de sus desarrolladores y están protegidos por leyes de propiedad
          intelectual. El usuario no está autorizado a copiar, modificar o
          distribuir el contenido sin autorización previa.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>8. Contacto</strong>
        </Typography>
        <Typography variant="body2" paragraph>
          Si tiene preguntas sobre estos términos y condiciones, puede
          comunicarse con nosotros a través de nuestro correo electrónico:{" "}
          <a href="mailto:app.smartdx@gmail.com">app.smartdx@gmail.com</a>.
        </Typography>

        <Typography variant="body2" align="right" sx={{ marginTop: 4 }}>
          Fecha de última actualización: [19 de Diciembre del 2024]
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
