import { ArrowBackIosNew, Cached, Logout } from "@mui/icons-material";
import { AppBar, Box, IconButton, Stack, Toolbar } from "@mui/material";
import React from "react";
import { useCurrentUser } from "../context/UserContext";
import { logout } from "../../helpers/authentication-helpers";
import { useNavigate } from "react-router";
import SmardxTitle from "../typography/SmardxTitle";

const DxNavbar = () => {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const reloadPage = () => {
    if (window.location.pathname === "/waiting-for-access") {
      window.location.href = "/"; // ToDo: Eventualmente debería ser la página de inicio
    } else {
      window.location.reload();
    }
  };
  const rootRoutes = ["/", "/authentication", "/waiting-for-access"];
  const goBack = () => {
    if (rootRoutes.includes(window.location.pathname)) {
      return;
    }
    navigate(-1);
  };

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          position: "fixed",
          width: "100%",
          zIndex: 900,
          top: 0,
          left: 0,
          right: 0,
        }}
      >
        <AppBar
          position="static"
          sx={{
            backgroundColor: "#202125",
          }}
        >
          <Toolbar>
            {!rootRoutes.includes(window.location.pathname) && (
              <IconButton
                size="large"
                edge="start"
                onClick={goBack}
                sx={{
                  backgroundColor: "transparent",
                }}
              >
                <ArrowBackIosNew />
              </IconButton>
            )}
            <Box>
              <SmardxTitle size={40} />
            </Box>
            <Stack
              direction="row"
              position="flex-end"
              sx={{
                ml: "auto",
              }}
            >
              <IconButton
                sx={{
                  backgroundColor: "transparent",
                }}
                onClick={reloadPage}
              >
                <Cached />
              </IconButton>
              {!!currentUser && (
                <IconButton
                  sx={{
                    backgroundColor: "transparent",
                  }}
                  onClick={logout}
                >
                  <Logout />
                </IconButton>
              )}
            </Stack>
          </Toolbar>
        </AppBar>
      </Box>
      <Toolbar />
    </>
  );
};

export default DxNavbar;
